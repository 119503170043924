@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap');

:root {
  --bg-main: #063252;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  /* Prevents scroll */
}

body {
  background-color: #063252;
  font-family: 'Nunito Sans', sans-serif;
  color: white;
}

.bg-header {
  position: absolute;
  width: 100%;
  height: 948px;
  /* background: var(--bg-main) url('../public/images/bg_header2.webp'); */
  background-repeat: repeat-x;
  background-position: center;
}

.bg-footer-wrapper {
  position: relative;
}

.bg-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 948px;
  /* background: var(--bg-main) url('../public/images/bg_footer2.webp'); */
  background-repeat: repeat-x;
  background-position: center;
}

.bg-overlay-linear {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.1), 
    rgba(0, 0, 0, 0.7)
  );
  z-index: 1;
}

.bg-overlay-radial {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.0) 80%, 
    rgba(0, 0, 0, 0.3)
  );
  z-index: 1;
}

.content {
  /* background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)), radial-gradient(rgba(0, 0, 0, 0.0) 80%, rgba(0, 0, 0, 0.3)); */
  position: relative;
  z-index: 1;
  /* background-attachment: fixed; */

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


#root {
  margin-bottom: 150px;
}
/* READ

https: //stackoverflow.com/questions/23236158/how-to-replicate-background-attachment-fixed-on-ios
https: //www.google.com/search?q=background-attachment%3A+fixed%3B+iphone+safari&oq=background-attachment%3A+fixed%3B+iphone+safari&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg60gEIMzg2NGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8

*/